import React from 'react'
import './nosotros.css'
import metalurgicaVideo1 from '../../assets/metalurgicaVideo-1.mp4'

const Nosotros = () => {
  return (
    <div className='nosotros'>
        <div className='nosotros-container'>
          <div className="container">
            <div className='left-nosotros'>
              <p style={{marginTop: '5rem'}}>Con más de <strong>30</strong> años de experiencia en el rubro <strong>metalúrgico</strong>, esta empresa familiar tiene como prioridad la <strong>calidad del servicio en todos sus pasos</strong>, contando con el equipamiento adecuado.
              <br />
              <br />
Nuestros principales mercados son la <strong>industria de grasas comestibles, química y farmacéutica</strong>; siendo nuestro mercado nicho las <strong>industrias margarineras</strong>, donde nos destacamos en la fabricación y reparación de máquinas elementales para el proceso.

<br />
<br />

En forma complementaria, ofrecemos nuestro <strong>asesoramiento técnico y de ingeniería</strong> para aquellas empresas que lo requieran.
</p>
            </div>

            <div className='right-nosotros' style={{marginTop: '3rem'}}>
                <video src={metalurgicaVideo1} autoPlay='true' loop='true' muted='true' style={{width: '500px',borderRadius: '12px'}}></video>
            </div>

          </div>
        </div>
    </div>
  )
}

export default Nosotros