import React from 'react'
import './footerStyles.css'
import { BsFacebook, BsInstagram } from 'react-icons/bs'
import { motion } from 'framer-motion'
import oneCreativo from '../../assets/img/logo-one/logo2021-2.png' 
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="container">
        <div className='upper-footer-content'>
        <div className='rights-footer'>
            <p>Av. Pedro Suárez 1874, B1838 Esteban Echeverría, Provincia de Buenos Aires</p>
            <p>© 2023 Metalurgica Mennano Hnos. Todos los derechos reservados</p>
          </div>
          <div className='socials-footer'>
            <motion.div
            whileHover={{scale: 1.1}}
            whileTap={{scale:0.9}}>
              <a target='_blank' href="https://instagram.com/metalurgicamenanno"><BsInstagram /></a> 
            </motion.div>            
            
          </div>
        </div>

        <hr />

        <div className='bottom-footer-content'>
     
          <motion.div             
            whileHover={{scale: 1.1}}
            whileTap={{scale:0.9}}
            className='oneCreativo-footer'>
            <a href="https://onecreativo.com/" target='_blank'>
              <span>Diseño y Desarrollo Por:</span>
              <img src={oneCreativo} alt="" />
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Footer