import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';

function TitulosAnimados() {
  const [currentTitle, setCurrentTitle] = useState(0);
  const titles = ['Aceros Inoxidables', 'Trabajos Especiales', 'Ingeniería y Diseño'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTitle((currentTitle) => (currentTitle === titles.length - 1 ? 0 : currentTitle + 1));
    }, 2000);
    return () => clearInterval(intervalId);
  }, [titles.length]);

  const transitions = useTransition(titles[currentTitle], {
    key: titles[currentTitle],
    from: { opacity: 0, fontSize: '4rem'},
    enter: { opacity: 1, fontSize: '5rem' },
    leave: { opacity: 0, fontSize: '4rem', position: 'absolute' },
  });

  return (
    <div style={{ height: '5rem' }}>
      {transitions((styles, item) => (
        <animated.h1 style={{ ...styles, fontSize: '3.5rem', textAlign: 'center', lineHeight: '1.2' }}>{item}</animated.h1>
      ))}
    </div>
  );
}

export default TitulosAnimados;