import React from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import './whatsapp-button.css'
import { motion } from 'framer-motion';

const Whatsapp = () => {
  return (
    <motion.div  whileHover={{scale: 1.1}} whileTap={{scale: 0.95}} className='whatsapp-button' >
        <a className='whatsapp-container' href="https://api.whatsapp.com/send/?phone=5491164319446&text=Hola%2C+escribo+desde+la+web%21&type=phone_number&app_absent=0" target='_blank'>
            <FaWhatsapp />
        </a>
    </motion.div>
  )
}

export default Whatsapp