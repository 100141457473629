import React from 'react'
import './mainStyles.css'
import { BsChevronDoubleDown } from 'react-icons/bs'
import { GiHexagonalNut } from 'react-icons/gi'
import { MdEngineering } from 'react-icons/md'
import { VscTools } from 'react-icons/vsc'
import { motion } from 'framer-motion'
import TitulosAnimados from './titulos/Titulos';
import { CgEditBlackPoint } from 'react-icons/cg'





import img1 from '../../assets/img/marcas/1.png';
import img2 from '../../assets/img/marcas/2.png';
import img3 from '../../assets/img/marcas/3.png';
import img4 from '../../assets/img/marcas/4.png';
import img5 from '../../assets/img/marcas/5.png';
import img6 from '../../assets/img/marcas/6.png';
import img7 from '../../assets/img/marcas/7.png';
import img8 from '../../assets/img/marcas/8.png';
import img9 from '../../assets/img/marcas/9.png';
import img10 from '../../assets/img/marcas/10.png';
import img11 from '../../assets/img/marcas/11.png';
import img12 from '../../assets/img/marcas/12.png';
import img13 from '../../assets/img/marcas/13.png';
import img14 from '../../assets/img/marcas/14.png';
import img15 from '../../assets/img/marcas/15.png';
import img16 from '../../assets/img/marcas/16.png';
import img17 from '../../assets/img/marcas/17.png';
import img18 from '../../assets/img/marcas/18.png';




const Main = () => {
  return (
    <>

      <div className='container'>
        <div className='main-container'>
          <h1 className='main-title'><TitulosAnimados /></h1>
          <hr className='line-main' />
          <p className='info-p-main'>30 Años de experiencia en la Fabricación de maquinarias y equipos para la Industria Alimenticia, Química y Farmacéutica</p>
        </div>
      </div>

      <div className='banner-main'>
        <BsChevronDoubleDown />
      </div>

      <div className='info-main-container'>
        <div className='container'>
          <div className='text-info-main'>
            <h2 className='title-info'>Que hacemos?<div className='line-title'></div> </h2>
          </div>
          <div className='boxes-info'>

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className='box-us maquinarias'
              transition={{
                duration: 0.4,
              }}
              whileHover={{ scale: 1.1, y: -20, boxShadow: '14px 14px 20px 0px rgba(0,0,0,0.75)' }}
            ><div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <MdEngineering style={{ fontSize: '4rem' }} />
                Ingenieria y Diseño
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className='box-us estructuras-metalicas'
              transition={{
                duration: 0.5,
              }}
              whileHover={{ scale: 1.1, y: -20, boxShadow: '14px 14px 20px 0px rgba(0,0,0,0.75)' }}

            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <GiHexagonalNut style={{ fontSize: '4rem' }} />
                Fabricacion
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className='box-us recipientes-tanques'
              transition={{
                duration: 0.4,
              }}
              whileHover={{ scale: 1.1, y: -20, boxShadow: '14px 14px 20px 0px rgba(0,0,0,0.75)' }}

            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <VscTools style={{ fontSize: '4rem' }} />
                Servicio Post Venta
              </div>


            </motion.div>
          </div>
        </div>

      </div>



          <div class="slider">
            <div class="slide-track">
              <div class="slides">
                <motion.img height={80} src={img1} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img2} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img3} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img4} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img5} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img6} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img7} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img8} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img9} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img10} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img11} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img12} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img13} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img14} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img15} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img16} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img17} alt="" />
              </div>
              <div class="slides">
                <motion.img height={80} src={img18} alt="" />
              </div>
            </div>
          </div>

     

      <div className="prod-info-main-container">
        <section className='container'>

          <div className='prod-info-main'>

            <div className='prod-info-main-text'>
              <h3 style={{ marginBottom: '10px', marginTop: '20px', display: 'flex', alignItems: 'center' }}>Ingenieria y Diseño</h3>
              <p style={{ marginBottom: '20px' }}>Brindamos servicio de Ingeniería, conceptual, básica, ejecutiva y de detalle en los siguientes rubros:</p>

              <ul style={{ listStyle: 'none', marginLeft: '30px', marginBottom: '30px' }}>
                <li style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><CgEditBlackPoint /> Instalaciones de Piping</li>
                <li style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><CgEditBlackPoint /> Recipientes y tanques</li>
                <li style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><CgEditBlackPoint /> Equipos de transporte de sólidos</li>
                <li style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><CgEditBlackPoint /> Transportes neumáticos</li>
                <li style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><CgEditBlackPoint /> Estructuras metálicas</li>
                <li style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}><CgEditBlackPoint /> Diseño de máquinas</li>
              </ul>
              {/* <Link to='/servicios'>
              <motion.div     
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
            className='button-info-main-text'
            whileHover={{ scale: 1.1}}
            whileTap={{ scale: 0.99 }}> Ver Mas <AiFillCaretRight className='icon-prod-info' /></motion.div>
            </Link> */}
            </div>
          </div>

          <hr />

          <div className='prod-info-main'>
            <div className='prod-info-main-text'>
              <h3> fabricacion  </h3>
              <p>Construcción de nuevos equipos satisfaciendo las distintas necesidades de los clientes.</p>
            </div>
          </div>

          <hr />

          <div className='prod-info-main'>
            <div className='prod-info-main-text'>
              <h3> Servicio post venta  </h3>
              <p>Puesta en marcha y asesoramiento técnico de los equipos entregados</p>
            </div>
          </div>

        </section>


      </div>
    </>
  )
}

export default Main