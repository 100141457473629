import { useState, useEffect, useRef } from 'react';
import './navbar.css'
import {AiFillCaretRight, AiOutlineArrowDown, AiOutlineArrowRight} from 'react-icons/ai'
import { motion } from 'framer-motion'
import { Link, useLocation } from 'react-router-dom'
import {RxHamburgerMenu} from 'react-icons/rx'
import { animateScroll as scroll } from 'react-scroll';

// logo prueba
  import logo from '../../assets/img/menanno-logo.png'

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [dropdown, setDropdown] = useState(false)

  function scrollTo0() {
    window.scrollTo(0, 0);
    setIsOpen(!isOpen);
  } 

  const location = useLocation();

  const handleScrollDropdown = (id) => {
    scroll.scrollTo(document.getElementById(id));
  };

  const handleDropdownToggle = () => {
    setDropdown(!dropdown);
  };

  function scrollToReactores() {
    window.scrollTo(0, 100);
  }

  function scrollToTanques() {
    window.scrollTo(0, 200);
  }

  function scrollToTolvas() {
    window.scrollTo(0, 300);
  }

  function scrollToCondensadores() {
    window.scrollTo(0, 400);
  }

  function scrollToCintas() {
    window.scrollTo(0, 500);
  }

  function scrollToHelicoidal() {
    window.scrollTo(0, 600);
  }  
  
  function scrollToTurboMezcladoras() {
    window.scrollTo(0, 1600);
  }

  function scrollToMargarina() {
    window.scrollTo(0, 1700);
  }

  function scrollToMFiltros() {
    window.scrollTo(0, 1800);
  }


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navClasses = `nav-container ${isScrolled ? 'scrolled' : ''}`;

  return (
    <div style={{width:'100%', display: 'flex', justifyContent: 'center', zIndex: '99999'}}>
      <nav className={navClasses}>
        <motion.img whileHover={{scale: 1.05}} className='logo-nav' src={logo} alt="" />
        <motion.span whileTap={{scale:0.8}} className='hamburguerMenu' onClick={() => setIsOpen(!isOpen)}><RxHamburgerMenu /></motion.span>
        <motion.ul     
        initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }} className={`list-tab ${isOpen ? 'open' : ''}`}>
          <Link to='/'>
            <motion.li     
            onClick={scrollTo0}
              transition={{ duration: 0.15}}
              whileHover={{ scale: 1.08, color: '#91d8f7'}}
              whileTap={{ scale: 0.99 }}
              className='tab-nav'>
              Inicio <AiOutlineArrowDown className='icon-nav' />
            </motion.li>
          </Link>

          <Link to='/nosotros'>
            <motion.li        
              onClick={scrollTo0}
              transition={{ duration: 0.15}}
              whileHover={{ scale: 1.08, color: '#91d8f7'}}
              whileTap={{ scale: 0.99 }}
              className='tab-nav'>Nosotros <AiOutlineArrowDown className='icon-nav' /></motion.li>
          </Link>

          <Link to='/servicios'>
            <motion.li        
              onClick={scrollTo0}
              transition={{ duration: 0.15}}
              whileHover={{ scale: 1.08, color: '#91d8f7'}}
              whileTap={{ scale: 0.99 }}
              className='tab-nav'>Servicios <AiOutlineArrowDown className='icon-nav' /></motion.li>
          </Link>

          <Link to='/productos'>
            <motion.li        
            onMouseEnter={() => handleDropdownToggle()}
            onMouseLeave={() => handleDropdownToggle()}
              transition={{ duration: 0.15}}
              whileHover={{color: '#91d8f7'}}
              whileTap={{ scale: 0.99 }}
              className='tab-nav'>Productos <AiOutlineArrowDown className='icon-nav' />
              {dropdown && <motion.div 
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                style={{position: 'absolute',left: '5px', marginTop: '20px'}}>
                <ul className='list-servicios-ul'>
                <Link to='/productos#reactores' onClick={scrollToReactores}>

                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Equipos de margarina</motion.li>
                    </Link>
                    <Link to='/productos#tanques' onClick={scrollToTanques}>
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Reactores y Tanques</motion.li>
                    </Link>

                    <Link to='/productos#tolvas' onClick={scrollToTolvas}>
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Tolvas</motion.li>
                    </Link>

                    <Link to='/productos#condensadores' onClick={scrollToCondensadores}>
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Intercambiador de calor</motion.li>
                    </Link>

                    <Link to='/productos#cintas-transportadoras' onClick={scrollToCintas}>
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Transporte de solidos</motion.li>
                    </Link>                    
                    
                    <Link to='/productos#transporte-helicoidal' onClick={scrollToHelicoidal}>
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight />  Turbo Mezcladoras</motion.li>
                    </Link>                    
                    
                    <Link to='/productos#turbo-mezcladoras' onClick={scrollToTurboMezcladoras} >
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Filtros</motion.li>
                    </Link>     
                                   
                    
                    <Link to='/productos#equipos-de-margarina' onClick={scrollToMargarina}>
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Estructuras Metalicas</motion.li>
                    </Link>                   

                                        <Link to='/productos#equipos-de-margarina' onClick={scrollToMargarina}>
                        <motion.li
                            whileHover={{scale: 1.03, color: '#ececec'}}
                            whileTap={{scale: 0.99, color: '#ff0000'}}
                            className='list-item-servicios'><AiFillCaretRight /> Trabajos Especiales a pedido</motion.li>
                    </Link>  
                    
                    
                </ul>
                </motion.div>}
              </motion.li>


          </Link>

          <Link to='/contacto'>
            <motion.li        
              onClick={scrollTo0}
              transition={{ duration: 0.15}}
              whileHover={{ scale: 1.08, color: '#fff'}}
              whileTap={{ scale: 0.99 }}
              className='tab-nav tab-special'><AiOutlineArrowRight className='icon-nav' /> Contacto </motion.li> 
          </Link>
        </motion.ul>
      </nav>
    </div>
  );
};

export default Navbar;