import './App.css';
import Main from './pages/main/Main';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Nosotros from './pages/nosotros/Nosotros';
import ErrPage from './pages/ErrPage/ErrPage';
import Servicios from './pages/Servicios/Servicios';
import Contacto from './pages/contacto/Contacto';
import Productos from './pages/productos/Productos';
import Whatsapp from './components/Whatsapp/Whatsapp';



function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
          <Routes>
            <Route path='/' element={<Main />}/>
            <Route path='/nosotros' element={<Nosotros />}/>
            <Route path='/servicios' element={<Servicios />}/>
            <Route path='/contacto' element={<Contacto />} />
            <Route path='/productos' element={<Productos />}/>
            <Route path='*' element={<ErrPage />}/>
          </Routes>
          <Whatsapp />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
