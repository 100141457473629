import React, { useState } from 'react';
import './accordion.css'

import { AiFillCaretDown } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import amasadora1 from '../../../assets/img/fotos-n-mennano/amasadora 1.jfif';
import cristalizador from '../../../assets/img/fotos-n-mennano/Cristalizador y plastificador.jpg';
import estructura from '../../../assets/img/transporte-solido/ESTRUCTURA METALICA.png';
import fabricacion from '../../../assets/img/fotos-n-mennano/fabricacion.jfif';
import filtro1 from '../../../assets/img/fotos-n-mennano/filtro 1.jpg';
import filtro2 from '../../../assets/img/fotos-n-mennano/Filtro 2.jpg';
import intercambiadorDeCalor1 from '../../../assets/img/fotos-n-mennano/intercambiador de calor 1.jfif';
import intercambiadorDeCalor2 from '../../../assets/img/fotos-n-mennano/intercambiador de calor 2.jfif';
import reactor1 from '../../../assets/img/fotos-n-mennano/Reactor 1.jfif';
import reactor2 from '../../../assets/img/fotos-n-mennano/reactor 2.jfif';
import roloEscamador1 from '../../../assets/img/fotos-n-mennano/rolo escamador 1.jpg';
import tanque1 from '../../../assets/img/fotos-n-mennano/tanques 1.jfif';
import tanque2 from '../../../assets/img/fotos-n-mennano/tanque 2.jfif';
import tolva1 from '../../../assets/img/fotos-n-mennano/tolvas1.jfif';
import tolva2 from '../../../assets/img/fotos-n-mennano/tolva 2.jfif';
import tolvaDeMaduracion1 from '../../../assets/img/fotos-n-mennano/Tolva de maduracion 1.jpg';
import tolvaDeMaduracion2 from '../../../assets/img/fotos-n-mennano/Tolva de maduracion 2.jfif';
import trabajosEspeciales1 from '../../../assets/img/fotos-n-mennano/trabajos especiales 1.jfif';
import trabajosEspeciales2 from '../../../assets/img/fotos-n-mennano/trabajos especiales 2.jfif';
import trabajosEspeciales3 from '../../../assets/img/fotos-n-mennano/trabajos especiales 3.jfif';
import trabajosEspeciales4 from '../../../assets/img/fotos-n-mennano/trabajos especiales 4.jfif';
import transporteHelicoidal from '../../../assets/img/fotos-n-mennano/trabajos especiales 5.jpeg';
import turboMezcladora1 from '../../../assets/img/fotos-n-mennano/turbo mezcladora 1.jpg';
import turboMezcladora2 from '../../../assets/img/fotos-n-mennano/turbo mezcladora.jfif';

import cintaTransportadora from '../../../assets/img/transporte-solido/CINTA TRANSPORTADORA.png'
import transporteHelicoidalfinal from '../../../assets/img/transporte-solido/Transporte-helicoidal.jpg'





  const items = [
    {
      id: 1,
      title: 'Equipos de Margarina',
      content: (
        <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

          <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={roloEscamador1} alt="" />
            <p style={{fontSize: '1.3rem'}}>Rolo Escamador</p>
            </li>
          <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={tolvaDeMaduracion1} alt="" />
            <p style={{fontSize: '1.3rem'}}>Tolvas de maduracion</p>
            </li>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

          <li style={{display: 'flex', alignItems: 'center', gap: '2rem', width: '50%'}}>
            <img style={{width: '60%'}} src={amasadora1} alt="" />
            <p style={{fontSize: '1.3rem'}}>Amasadora</p>
          </li>
          <li style={{display: 'flex', alignItems: 'center', gap: '2rem', width: '50%'}}>
            <img style={{width: '60%'}} src={cristalizador} alt="" />
            <p style={{fontSize: '1.3rem'}}>Plastificado y Cristalizador</p>
          </li>
          </div>
        </ul>)
    },
    {
      id: 2,
      title: 'Reactores y Tanques',
      content: (
        <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

        <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem',width: '50%'}}>
          <img style={{width: '60%'}} src={reactor1} alt="" />
          </li>
        <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
          <img style={{width: '60%'}} src={reactor2} alt="" />
          </li>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

        <li style={{display: 'flex', alignItems: 'center',justifyContent: 'center', gap: '2rem', width: '50%'}}>
          <img style={{width: '60%'}} src={tanque1} alt="" />
        </li>
        <li style={{display: 'flex', alignItems: 'center', gap: '2rem', width: '50%'}}>
          <img style={{width: '60%'}} src={tanque2} alt="" />
        </li>
        </div>
      </ul>
      )
    },
    {
      id: 3,
      title: 'Tolvas',
      content: (
        <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

        <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem',width: '50%'}}>
          <img style={{width: '60%'}} src={tolva1} alt="" />
          </li>
        <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
          <img style={{width: '60%'}} src={tolva2} alt="" />
          </li>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

        <li style={{display: 'flex', alignItems: 'center',justifyContent: 'center', gap: '2rem', width: '50%'}}>
          <img style={{width: '60%'}} src={tolvaDeMaduracion1} alt="" />
        </li>
        <li style={{display: 'flex', alignItems: 'center', gap: '2rem', width: '50%'}}>
          <img style={{width: '60%'}} src={tolvaDeMaduracion2} alt="" />
        </li>
        </div>
      </ul>
      )
    },
    {
        id: 4,
        title: 'Intercambiador de Calor',
        content: (
          <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
  
          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={intercambiadorDeCalor1} alt="" />
            </li>
          <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={intercambiadorDeCalor2} alt="" />
            </li>
          </div>
        </ul>
        )
      },
      {
        id: 5,
        title: 'Transporte de Solidos',
        content: (
          <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
            <div style={{display: 'flex', justifyContent: 'space-evenly', gap: '2rem'}}>
  
            <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
              <img style={{width: '60%'}} src={cintaTransportadora} alt="" />
              <p style={{fontSize: '1.3rem'}}>Cintas Transportadoras</p>
              </li>
            <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
              <img style={{width: '60%'}} src={transporteHelicoidalfinal} alt="" />
              <p style={{fontSize: '1.3rem'}}>Transporte Helicoidal</p>
              </li>
            </div>
          </ul>)
      },
      {
        id: 6,
        title: 'Turbo Mezcladoras',
        content: (
          <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
  
          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={turboMezcladora1} alt="" />
            </li>
          <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={turboMezcladora2} alt="" />
            </li>
          </div>
        </ul>
        )
      },
      {
        id: 7,
        title: 'Filtros',
        content: (
          <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
  
          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={filtro1} alt="" />
            </li>
          <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={filtro2} alt="" />
            </li>
          </div>
        </ul>
        )
      },
      {
        id: 8,
        title: 'Estructuras Metalicas',
        content: (
          <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
  
          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2rem',width: '50%'}}>
            <img style={{width: '60%'}} src={estructura} alt="" />
            </li>
          </div>
        </ul>
        )
      },
      {
        id: 9,
        title: 'Trabajos Especiales a pedido',
        content: (
          <ul style={{listStyle: 'none', width: '100vw' , marginLeft: '10px', marginRight: '10px'}}>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
  
            <li style={{display: 'flex', alignItems: 'center',justifyContent: 'center',gap: '2rem',width: '50%'}}>
              <img style={{width: '60%'}} src={trabajosEspeciales1} alt="" />
 
              </li>
            <li style={{display: 'flex', alignItems: 'center', gap: '2rem',width: '50%'}}>
              <img style={{width: '60%'}} src={trabajosEspeciales2} alt="" />

              </li>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
  
            <li style={{display: 'flex', alignItems: 'center',justifyContent: 'center', gap: '2rem', width: '50%'}}>
              <img style={{width: '60%'}} src={trabajosEspeciales3} alt="" />

            </li>
            <li style={{display: 'flex', alignItems: 'center', gap: '2rem', width: '50%'}}>
              <img style={{width: '60%'}} src={trabajosEspeciales4} alt="" />

            </li>
            </div>
          </ul>)
      }
  ];

function Accordion() {
const [AccInfo, setAccInfo] = useState(false);  

    const [activeItem, setActiveItem] = useState(null);

  const location = useLocation();
  const id = location.pathname.split('#')[1];
  
  const [activeIndex, setActiveIndex] = useState(-1);
  
  useEffect(() => {
    const index = items.findIndex(item => item.id === Number(id));
    if (index !== -1) {
      setActiveIndex(index);
      const element = document.getElementById(`item-${index}`);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [id]);


    
  
    const toggleAccordion = (itemId) => {
      if (activeItem === itemId) {
        setActiveItem(null);
      } else {
        setActiveItem(itemId);
      }
    };
  
    return (
      <div className="accordion" index={activeIndex}>
        {items.map((item, index) => (
          <div key={item.id} id={`item-${index}`} className="accordion-item">
            <div className="accordion-header"  onClick={() => toggleAccordion(item.id)}>
                <div className="title-desc-accordion">
                    <h3 style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>{item.title} <AiFillCaretDown /></h3>
                </div>
              <span className="accordion-button">{activeItem === item.id ? '-' : '+'}</span>
            </div>
            {activeItem === item.id && (
              <div className="accordion-content">
                <div className="accordion-images">
                  {item.id == 1 ||  5 ? item.content : 
                  item.images.map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index}`} />
                    ))}

              </div>
                </div>
            )}
          </div>
        ))}
      </div>
    );
  }

export default Accordion;