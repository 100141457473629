import React from 'react'
import './contacto.css'
import { AiFillCaretDown, AiOutlineMail } from 'react-icons/ai'
import { BiMailSend } from 'react-icons/bi'
import Typewriter  from 'typewriter-effect';
import { motion } from 'framer-motion';
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';

const Contacto = () => {
  return (
    <div className='contacto'>
        <div className="container">
            <div className='contact-box-container'>

                <div className='title-contact'>
                    <h3 ><u>
    Contactanos
                                    </u></h3>
                    <p>Deja tu consulta y un asesor se estara contactando en breve <AiFillCaretDown /></p>
                </div>

                <div className='contact-container'>

                <form className='form-container' action="https://formsubmit.co/info@metalurgicamenanno.com" method="POST">
                    <motion.input whileTap={{scale: 1.1}} type="text" name="name" placeholder='Nombre y Apellido' required/>
                    <motion.input whileTap={{scale: 1.1}} type="email" name="email" placeholder='E-mail' required/>
                    <motion.input whileTap={{scale: 1.1}} type="number" name="number" placeholder='Numero de telefono' required/>
                    <motion.textarea whileTap={{scale: 1.1}} name="textarea" placeholder='Mensaje...' id="textarea" type='textarea' cols="30" rows="10" required></motion.textarea>
                    <motion.button
                        whileHover={{scale: 1.05, color: 'black', borderColor: 'black'}}
                        whileTap={{scale: 0.95}}
                        className='button-send-form' type="submit">Enviar <BiMailSend/></motion.button>
                </form>

                    <div>
                        <div className="contact-data">
                            <div className=''>
                                <p><b>Direccion:</b> Av. Pedro Suárez 1874, Esteban Echeverría, Provincia de Buenos Aires, Argentina.  </p>
                                <p><b>Horario de Atencion:</b> Lunes a Viernes de 8:00 a 12:00 y 13:30 a 17:00</p>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3275.499089483771!2d-58.43615402432093!3d-34.81854207287808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcd3917704127d%3A0x433e4c3ea0d98b8e!2sMetal%C3%BArgica%20Menanno%20Hnos!5e0!3m2!1ses-419!2sar!4v1682619045792!5m2!1ses-419!2sar" width="500" height="250" style={{border:0, margin: '20px 0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                <a href='tel:541142962024' style={{display: 'flex', alignItems: 'center', gap: '1rem', color: '#000'}}><b><BsFillTelephoneOutboundFill /> </b> +54 1142962024</a>
                                <a href='mailto:info@metalurgicamenanno.com' style={{display: 'flex', alignItems: 'center', gap: '1rem', color: '#000'}}><b><AiOutlineMail /> </b> Info@metalurgicamenanno.com</a>
                            </div>

                            <hr />
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Contacto