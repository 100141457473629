import React from 'react';
import { AiFillCaretDown, AiOutlineCaretDown } from 'react-icons/ai';
import './productos.css';

import Accordion from './Accordion/Accordion';


const Productos = () => {  
  
    return (
      <div className="productos">
        <div className="container">
          <h3 className="title-prods" style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>Nuestros Productos <AiFillCaretDown /></h3>
          <div className="container">

          <div className="productos-container">
        <Accordion/>
          </div>
          </div>
        </div>
      </div>
    );
  };

export default Productos;