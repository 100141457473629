import React from 'react'
import './serviciosStyles.css'
import {BiChevronDownCircle} from 'react-icons/bi'
import {motion} from 'framer-motion'
import {AiFillCaretDown, AiOutlineRightCircle} from 'react-icons/ai'
import {CgEditBlackPoint} from 'react-icons/cg'
import imgServicios from '../../assets/img/servicios/SERVICIOS.png' 


const Servicios = () => {
  return (
    <div className='servicios'>
        <div className='servicios-container'>
            <div className="container">
                <div className='title-servicios'>
                    <motion.div
                          initial={{ opacity: 0, x: -100 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          viewport={{ once: true }}
                    className='title-button-services'>
                        <u><h3>Nuestros Servicios</h3></u>
                    </motion.div>
                </div>
            </div>

            <div className='container'>
                <div style={{display: 'flex',width: '100%', justifyContent: 'space-around'}}>

                
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        <hr />
                        <h3 style={{marginBottom: '10px', marginTop: '20px', display:'flex', alignItems: 'center'}}>Ingenieria y Diseño     <AiFillCaretDown /></h3>
                        <p style={{marginBottom: '20px'}}>Brindamos servicio de Ingeniería, conceptual, básica, ejecutiva y de detalle en los siguientes rubros:</p>

                        <ul style={{listStyle: 'none', marginLeft: '30px', marginBottom: '30px'}}>
                            <li style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}><CgEditBlackPoint /> Instalaciones de Piping</li>
                            <li style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}><CgEditBlackPoint /> Recipientes y tanques</li>
                            <li style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}><CgEditBlackPoint /> Equipos de transporte de sólidos</li>
                            <li style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}><CgEditBlackPoint /> Transportes neumáticos</li>
                            <li style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}><CgEditBlackPoint /> Estructuras metálicas</li>
                            <li style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}><CgEditBlackPoint /> Diseño de máquinas</li>
                        </ul>
                    </div>
            <hr />
                <h3 style={{ marginTop: '20px', marginBottom: '20px'}}>Construcción de máquinas y equipos</h3>
            <hr />
                    <h3 style={{ marginTop: '20px', marginBottom: '20px'}}>PostVenta</h3>
                    <hr />
                    <h3 style={{ marginTop: '20px', marginBottom: '20px'}}>Corte con láser, plasma y guillotina</h3>
                    <hr />
                    <h3 style={{ marginTop: '20px', marginBottom: '20px'}}>Tornería en general</h3>
                    <hr />

                </div>


                <img src={imgServicios} style={{height: '400px'}} alt="" />

                </div>
            </div>


    

    </div>
    </div>
    )
}

export default Servicios